.rhap_container  {
  padding: 0;
  outline: none;
  box-shadow: none;
}

.rhap_horizontal-reverse .rhap_controls-section {
  flex: none;
  margin-right: 4.1rem;
}

.rhap_additional-controls {
  display: none;
}

.rhap_volume-controls {
  display: none;
}

.rhap_main-controls-button {
  color: #ff7654;
  outline: none;
}

.rhap_progress-filled, .rhap_progress-indicator {
  background-color: #ff7654;
  outline: none;
}

@media screen and (max-width: 450px) {
  .rhap_horizontal-reverse {
    flex-direction: column-reverse;
  }

  .rhap_horizontal-reverse .rhap_controls-section {
    margin-right: 0;
    margin-bottom: 3rem;
    align-self: center;
  }
}

.isDisabled button:hover,
.isDisabled .rhap_progress-bar {
  cursor: not-allowed;
}
