h1, p {
  font-family: Lato;
}

.player-wrapper {
  width: 480px;
  height: 270px;
}

.react-player {

  background: rgba(0, 0, 0, 0);
}
@media screen and (max-width: 600px) {
  .react-player {
    max-width: 370px;
    max-height: 270px;
  }
}
